<template>
  <div>
    <ManageTutorial />
  </div>
</template>
<script>
import ManageTutorial from "@/components/Tutorial/ManageTutorial";
export default {
  components: {
    ManageTutorial,
  },
  created() {},
};
</script>
c
